import React from 'react'
import styles from './styles/type2.module.scss'
import { BASE_URL } from "@/api";

interface ButtonType2 {
  link: string
  text: string
  id: number | null
  couponLink?: string,
    typeFilter?: string,
    type?: string | null,
    from?: string,
    storeSlug?: string,//传from 必传这个
}

export default function Type2({
  link,
  text,
  id,
  couponLink = '',
    typeFilter='',
    type,
    from,
    storeSlug,
}: ButtonType2) {


    const goLink = ()=>{
        const href = window.location.href.split('?')[0]
            const urlMaps:any = {
                'valid': `${href}?id=${id}&typeFilter=${typeFilter}`,
                'expiredValid': `${href}?id=${id}`
            }
            const url = type ? urlMaps[type] : `${href}?id=${id}`
            window.open(url, '_blank')
    }

  return (
    <a href={link}
       rel="nofollow"
       className={styles.type2}>
      <div
        className={styles.btn}
        onClick={() => {
          if (id === null) {
            const href = window.location.href.split('?')[0]
            window.open(`${href}${couponLink}`, '_blank')
          }else if(from && ['savedOffers', 'category_page'].some(v=>v === from)){
              let host = BASE_URL
              window.open(`${host}/coupons/${storeSlug}?id=${id}`,'_blank')
          } else {
              goLink()
          }
        }}
      >
        <span className={styles.pc}>{text}</span>
        <span className={styles.mobile}>{text}</span>
      </div>
    </a>
  )
}
