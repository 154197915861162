/**
 * 复制
 */
export async function copyText(text: string) {
  await navigator.clipboard.writeText(text)
}

/**
 * 邮件校验
 */
export const emailRegExp = (value: string) =>
  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)

export const HasUrlCode = (url: string, name: string) => {
  const link = new URLSearchParams(url)
  const id = link.get(name)
  return id
}

// 跳转商家
export const goLink = (
  link: string,
  id: number | string,
  couponLink?: string
) => {
  const href = window.location.href.split('?')[0]
  if (couponLink || couponLink !== '') {
    window.open(`${href}${couponLink}?id=${id}`, '_blank')
  } else {
    window.open(`${href}?id=${id}`, '_blank')
  }
  window.location.href = link
}

//判断是否是googlebot 是否允许抓取

/**
 * @return false 不允许抓取
 * true 允许
 * **/
// export const shouldAllowCrawling = ()=> {
//   const userAgent = navigator.userAgent.toLowerCase();
//   // const userAgent = 'compatible; Googlebot/2.1; +http://www.google.com/bot.html'
//   const isBot = userAgent.includes('googlebot') || userAgent.includes('Googlebot')
//   return !isBot
// }




export function getUrlParams(url: string) {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split('?')[1]
  if (!urlStr) return
  // 创建空对象存储参数
  let obj: any = {}
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split('&')
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split('=')
    obj[arr[0]] = arr[1]
  }
  return obj
}


export function setToken(token: string){
  localStorage.setItem('token', token)
  document.cookie = `token=${token}; path=/`;
}

export function resetToken(){
  localStorage.setItem('token','')
  document.cookie = 'token' + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export function getToken(){
  return localStorage.getItem('token')
}

